































































































































































































































































































































































































































import VueBase from '@/VueBase'
import { Component, Watch } from 'vue-property-decorator'
import { formatTimestamp } from '@/utils/utils'

function isValidRegex(pattern: string) {
  try {
    new RegExp(pattern)
    return true
  } catch (e) {
    return false
  }
}

@Component({
  name: 'SystemSettings',
  filters: {
    formatTimestamp(date: number | any) {
      return formatTimestamp(date)
    },
  },
})
export default class SystemSettings extends VueBase {
  private page = 1
  private pageSize = 20
  private total = 0
  private tableData: Array<any> = []
  private currentChange(val: number) {
    this.page = val
    this.GetUrlBlacklist()
  }

  private enable_mfa = false
  private async getEnableMfa() {
    const res = await this.services.authority.getMfaConfig()
    if (res.status === 201) {
      this.enable_mfa = res.data.enable_mfa
    }
  }

  private async handleMFA(enable_mfa: any) {
    const res = await this.services.authority.setMfaConfig({ enable_mfa })
    if (res.status === 201) {
      this.$message.success('设置成功')
    }
  }
  // api聚合方式
  private reflashFlag = false
  private Base64 = require('js-base64').Base64
  private readonly: boolean = !this.btnRole.includes(44)

  private cache = 1
  private cacheIo = false
  private cacheTime = '00:00'

  private radio: string = 'agent'
  private defaultProps: any = {
    children: 'type_value',
    label: 'vul_name',
  }

  private operator = {
    1: '等于',
    2: '不等于',
    3: '包含',
    4: '不包含',
    5: '存在 Key 等于',
    6: '不存在 Key 等于',
  }

  private cacheTimeChange(date: Date) {
    console.log(date)
  }

  private templateForm: any = {
    content: [],
    fid: 1,
    status: false,
  }
  private isSelectAll = false
  private strategyTypeList: Array<{
    level_id: number
    level_name: number
    type_value: Array<{
      strategy_id: number
      level_id: number
      state: string
      vul_name: string
    }>
  }> = []

  // @Watch('gather.enable_version_header', { immediate: true })
  // onChangeValue(newVal: any) {
  //   if (!newVal) {
  //     ;(this.gather as any).version_header_name = ''
  //     ;(this.gather as any).version_header_value = ''
  //   }
  // }
  private async strategyTypes() {
    this.loadingStart()
    const { status, msg, data } = await this.services.setting.strategyTypes()
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    if (status !== 201) {
      this.$message.error(msg)
      return
    }
    this.strategyTypeList = data
      .map((item: any) => {
        item.strategy_id = item.level_name
        item.vul_name = item.level_name
        item.isCollapse = item.type_value?.length > 0
        item.type_value = item.type_value.map((ele: any) => {
          ele.isCollapse = false
          return ele
        })
        return item
      })
      .filter((item: any) => item.isCollapse)
  }

  radioChange(val: any) {
    if (val == 'agent') return
    ;(this.$refs.tree as any)?.setCheckedKeys(this.templateForm.content || [])
    console.log('val', val)
  }
  async cressValidGet() {
    const res = await this.services.setting.getCrossValid({})
    if (res.status === 201) {
      this.templateForm.status = res.data.validation_status
      this.templateForm.content = [...res.data.strategy_id]
      ;(this.$refs.tree as any)?.setCheckedKeys(this.templateForm.content || [])
    } else {
      this.$message.error(res.msg)
    }
  }
  async crossValidEdit() {
    // 编辑确认
    this.templateForm.content = (
      (this.$refs.tree as any)?.getCheckedKeys() || []
    ).filter((item: any) => typeof item === 'number')
    const param = {
      strategy_id: [...this.templateForm.content],
      validation_status: this.templateForm.status,
    }
    this.loadingStart()
    const res = await this.services.setting.updateCrossValid(param)
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error('保存失败，请重试')
      return
    }
    this.$message.success('保存成功')
  }
  private async getDocHandler() {
    let res = await this.services.setting.getDocuments({})
    if (res.status === 201) {
      window.open(res.data.url)
    } else {
      this.$message.error(res.msg)
    }
  }

  private async cleanData() {
    this.loadingStart()
    let obj = {
      clean_time: this.cacheTime,
      days_before: this.cache,
      enable: this.cacheIo,
    }
    const res = await this.services.setting.dataClean(obj)
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error('保存失败，请重试')
      return
    }
    this.$message.success('保存成功')
  }
  // 查询清理配置时间
  private async getClineData() {
    this.loadingStart()
    let obj = {}
    const res = await this.services.setting.getDataClean(obj)
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.cache = res.data.days_before
    this.cacheTime = res.data.clean_time
    this.cacheIo = res.data.enable
  }
  // 立即清理
  private async dataCleanTask() {
    this.loadingStart()
    let obj = {
      days_before: this.cache,
    }
    const res = await this.services.setting.dataCleanTask(obj)
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.$message.success('清理成功')
  }

  private blackData: any = []

  private async deleteUrlBlacklist(data: any) {
    const res = await this.services.setting.deleteUrlBlacklist(data)
    if (res.status !== 201) {
      this.$message.error('黑名单删除失败，请重试')
      return
    }
    this.$message.success('黑名单删除成功')
  }

  private showAddBlack: boolean = false
  private addBlackData: any = {
    target: 1,
    operator: '',
    value: '',
    value1: '',
    value2: '',
    state: 1,
    description: '',
  }
  private addBlacklist() {
    this.showAddBlack = true
    this.addBlackData = {
      target: 1,
      operator: '',
      value: '',
      value1: '',
      value2: '',
      state: 1,
      description: '',
    }
  }
  private handleClose() {
    this.showAddBlack = false
    this.addBlackData = {
      target: 1,
      operator: '',
      value: '',
      value1: '',
      value2: '',
      state: 1,
      description: '',
    }
  }

  handleEdit(row: any) {
    this.showAddBlack = true
    this.addBlackData = { ...row }
  }

  async saveBlack() {
    if (this.addBlackData.target == 2) {
      this.addBlackData.operator = 7
    } else if (this.addBlackData.target == 1) {
      this.addBlackData.operator = 3
    }
    const res = { ...this.addBlackData }
    if (this.addBlackData.operator === 7) {
      if (!res.value1 || !res.value2) {
        this.$message.error('key或者value不能为空')
        return
      }
      if (!isValidRegex(res.value2)) {
        this.$message.error('value不为正则表达式')
        return
      }
      res.value =
        this.Base64.encode(res.value1) + ':' + this.Base64.encode(res.value2)
    }

    if (!res.value) {
      this.$message.error('value不能为空')
      return
    }

    if (this.addBlackData.id) {
      await this.editUrlBlacklist(res)
    } else {
      await this.createUrlBlacklist(res)
    }
    this.showAddBlack = false
    this.GetUrlBlacklist()
  }

  async changeState(row: any) {
    const data = { ...row }
    if (data.operator === 7) {
      const arr = data.value.split(':')
      data.value = this.Base64.encode(arr[0]) + ':' + this.Base64.encode(arr[1])
    }

    const res = await this.services.setting.editUrlBlacklist({
      ...data,
      state: data.state ? 0 : 1,
    })
    if (res.status !== 201) {
      this.$message.error('状态变更失败，请重试')
      return
    }
    this.$message.success('状态变更成功')
    this.GetUrlBlacklist()
  }

  private async createUrlBlacklist(data: any) {
    const res = await this.services.setting.createUrlBlacklist(data)
    if (res.status !== 201) {
      this.$message.error('黑名单创建失败，请重试')
      return
    }
    this.$message.success('黑名单创建成功')
  }

  private async editUrlBlacklist(data: any) {
    const res = await this.services.setting.editUrlBlacklist(data)
    if (res.status !== 201) {
      this.$message.error('黑名单编辑失败，请重试')
      return
    }
    this.$message.success('黑名单编辑成功')
  }

  private async GetUrlBlacklist() {
    const params = {
      page: this.page,
      page_size: this.pageSize,
    }
    const res = await this.services.setting.getUrlBlacklist(params)
    const { status, msg, data, page } = res
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    res.data.forEach((item: any) => {
      if (item.operator === 7) {
        const arr = item.value.split(':')
        item.value1 = this.Base64.decode(arr[0])
        item.value2 = this.Base64.decode(arr[1])
        item.value =
          this.Base64.decode(arr[0]) + ':' + this.Base64.decode(arr[1])
      }
    })
    this.blackData = res.data
    this.total = page.alltotal
  }

  async deleteBlack(row: any, _index: any) {
    await this.deleteUrlBlacklist(row)
    this.page = 1
    this.GetUrlBlacklist()
  }

  private gather = {
    method_pool_max_length: 5000,
    enable_qps_rate_limit: false,
    qps_rate_limit: 0,
    token_bucket_pool_size: 5000,
    enable_version_header: false,
    version_header_name: '',
    version_header_value: '',
    api_coverage: 2,
    gather_res_body: true,
  }

  private async gatherChange() {
    if (!(this.gather as any).version_header_name) {
      delete (this.gather as any).version_header_name
    }

    if (
      this.gather.version_header_name &&
      !/^[a-zA-Z0-9_-]{1,10}$/.test(this.gather.version_header_name)
    ) {
      this.$message.error('输入最多10个字符，仅限字母、数字、-、_')
      return
    }
    const res = await this.services.setting.setDataGather({
      ...this.gather,
      token_bucket_pool_size: 5000,
    })
    this.saveSink()
    this.savePrefLevel()
    if (res.status !== 201) {
      this.$message.error('保存失败，请重试')
      return
    }
    this.$message.success('保存成功')
  }

  private async getGather() {
    const res = await this.services.setting.getDataGather()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    const gather = {
      ...res.data,
      api_coverage: res.data?.api_coverage || 2,
    }
    this.gather = gather
  }

  private warning: any = {
    error_time: '',
    offline_time: '',
  }
  async getProjectWarning() {
    const res = await this.services.setting.getProjectWarning()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.warning = res.data
  }

  async saveWarning() {
    const res = await this.services.setting.projectWarning(this.warning)
    if (res.status !== 201) {
      this.$message.error('保存失败，请重试')
      return
    }
    this.$message.success('保存成功')
  }

  private show_en_ref: boolean = true
  async getEnRef() {
    const { data, status } = await this.services.setting.getShowEnRef()
    if (status !== 201) return
    this.show_en_ref = data.show_en_ref
  }

  async saveShowEnRef() {
    const { status, msg } = await this.services.setting.editShowEnRef({
      show_en_ref: this.show_en_ref,
    })
    if (status !== 201) {
      this.$message.error('保存失败，请重试')
      return
    }
    this.$message.success('保存成功')
  }

  private report_validated_sink: boolean = false
  async getSink() {
    const { data, status } = await this.services.setting.getSink()
    if (status !== 201) return
    this.report_validated_sink = data.report_validated_sink
  }
  async saveSink() {
    const ref = await this.services.setting.editSink({
      report_validated_sink: this.report_validated_sink,
    })
  }
  private pref_level: number = 1 // 0-高  1-中 2-低
  async getPrefLevel() {
    const { data, status } = await this.services.setting.getPrefLevel()
    if (status !== 201) return
    this.pref_level = data.pref_level
  }
  async savePrefLevel() {
    const ref = await this.services.setting.editPrefLevel({
      pref_level: this.pref_level,
    })
  }
  private scaSetting: Object = {
    // sca_base_url: "",
    // sca_max_retry_count: 3,
    // sca_timeout: 5,
    // sca_token: ""
  }
  async getScaSetting() {
    const { data, status } = await this.services.setting.getScaSetting()
    if (status !== 201) return
    this.scaSetting = { sca_base_url: '', ...data }
  }
  async saveScaSetting() {
    let data: any = {
      ...this.scaSetting,
    }
    if (!data.sca_base_url) {
      delete data.sca_base_url
    }
    const { status, msg } = await this.services.setting.editScaSetting(data)
    if (status !== 201) {
      this.$message.error('保存失败，请重试')
      return
    }
    this.$message.success('保存成功')
  }

  /**下面是系统配置信息 卡片相关 */

  private domain: string = ''
  async getDomain() {
    const { data, status } = await this.services.setting.getDomain()
    if (status !== 201) return
    this.domain = data.domain
  }
  async editDomain() {
    const { status, msg } = await this.services.setting.editDomain({
      domain: this.domain,
    })
    return status
  }

  private report_upload_throttle: string = ''
  private serverQpsEnble: boolean = false
  async getReportUploadThrottle() {
    const { data, status } =
      await this.services.setting.getReportUploadThrottle()
    if (status !== 201) return
    if (data.report_upload_throttle) {
      this.report_upload_throttle = (data.report_upload_throttle ?? '').replace(
        '/s',
        ''
      )
      this.serverQpsEnble = true
    } else {
      this.serverQpsEnble = false
    }
  }
  async editReportUploadThrottle() {
    let report_upload_throttle = this.report_upload_throttle
    if (this.serverQpsEnble) {
      report_upload_throttle = report_upload_throttle + '/s'
    } else {
      report_upload_throttle = ''
    }
    const { status, msg } =
      await this.services.setting.editReportUploadThrottle({
        report_upload_throttle,
      })
    return status
  }

  private healthCheck: Object = {
    // heartbeat: false,
    // heartbeat_server_url: ""
  }
  async getHealthCheck() {
    const { data, status } = await this.services.setting.getHealthCheck()
    if (status !== 201) return
    this.healthCheck = data || {}
  }
  async editHealthCheck() {
    const { status, msg } = await this.services.setting.editHealthCheck(
      this.healthCheck
    )
    return status
  }
  private validMethodStatus: any = 0
  private changeValidMethodStatus(val: any) {
    this.report_validated_sink = val
    if (val == 0) {
      this.report_validated_sink = false
    }
    if (val == 1) {
      this.report_validated_sink = true
    }
  }

  private changeEnableVersionStatus(val: any) {
    this.gather.enable_version_header = val
  }

  private changeEnableGpsStatus(val: any) {
    this.gather.enable_qps_rate_limit = val
  }

  private responseStatus: any = 0
  private changeResp1Status(val: any) {
    this.gather.gather_res_body = val
    if (val == 0) {
      this.gather.gather_res_body = false
    }
    if (val == 1) {
      this.gather.gather_res_body = true
    }
  }

  private totpStatus: any = 0
  private changeTotpStatus(val: any) {
    // this.totpStatus = val
    this.enable_mfa = val
  }

  private ldapStatus: any = 0
  private changeLdapStatus(val: any) {
    this.ldapStatus = val == true ? 1 : 0
    this.ldapObj['enable'] = val
    if (val != 1) {
      // todo 清理ladp已经配置的地址
    }
  }

  private ldapObj: Object = {
    // "server_uri": "",
    // "ldap_bind_dn": "",
    // "ldap_bind_password": ""
  }
  async getLdapSetting() {
    const { data, status } = await this.services.setting.getLdapSetting()
    if (status !== 201) return
    this.ldapObj = data
    // if (data.server_uri) {
    //   this.ldapStatus = 1
    // } else {
    //   this.ldapStatus = 0
    // }

    if (data.enable == true) {
      this.ldapStatus = 1
    } else {
      this.ldapStatus = 0
    }
  }
  async editLdapSetting() {
    const { status, msg } = await this.services.setting.editLdapSetting(
      this.ldapObj
    )
    return status
  }

  getSysSettings() {
    this.getDomain()
    this.getReportUploadThrottle()
    this.getHealthCheck()
    this.getLdapSetting()
  }

  async savaSysSettings() {
    if (this.btnRole.includes(60)) await this.handleMFA(this.enable_mfa)
    await this.editDomain()
    await this.editReportUploadThrottle()
    await this.editHealthCheck()
    const status = await this.editLdapSetting()
    if (status !== 201) {
      this.$message.error('保存失败，请重试')
      return
    }
    this.$message.success('保存成功')
  }
  /**下面是系统配置信息 卡片相关 结束 */
  private dialogVisible: boolean = false
  private focusForm: any = {
    age: '',
  }
  //{"JAVA": 1, "PYTHON": 2, "PHP": 3, "GO": 4}
  private languageOptions: any = [
    { id: 1, label: 'JAVA' },
    { id: 2, label: 'PYTHON' },
    { id: 3, label: 'PHP' },
    { id: 4, label: 'GO' },
  ]
  private focusData: any = []
  async getFocusList() {
    const { data, status } = await this.services.setting.getFocusList()
    if (status !== 201) return
    this.focusData = data
  }
  saveFocus() {
    ;(this.$refs.keyFormRef as any)?.validate(async (validate: any) => {
      if (!validate) return
      const { status } = await this.services.setting.addFocus(this.focusForm)
      if (status !== 201) {
        this.$message.error('白名单创建失败，请重试')
        return
      }
      this.$message.success('白名单创建成功')
      this.getFocusList()
      this.dialogVisible = false
    })
  }

  async deleteFocus(row: any) {
    const { status } = await this.services.setting.delFocus(row.id)
    if (status !== 201) {
      this.$message.error('白名单删除失败，请重试')
      return
    }
    this.$message.success('白名单删除成功')
    this.getFocusList()
  }

  handleDownload() {
    window.open('/api/v2/package_focus/download')
  }

  get responseHeader() {
    return this.gather.version_header_name
  }

  created() {
    this.getEnableMfa()
    this.getGather()
    this.getClineData()
    this.GetUrlBlacklist()
    this.strategyTypes()
    this.cressValidGet()
    this.getProjectWarning()
    this.getEnRef()
    this.getSink()
    this.getFocusList()
    this.getPrefLevel()
    this.getScaSetting()
    this.getSysSettings()
  }
}
